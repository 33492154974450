<template>
  <div>
    <v-autocomplete
      :items="items"
      :loading="loading"
      :item-text="(item) => item.Name"
      :item-value="(item) => item.Id"
      v-bind="$attrs"
      v-on="$listeners"
      :hide-details="$attrs['hide-details'] === 'false' ? false : 'auto'"
      :outlined="$attrs['outlined'] === 'false' ? false : true"
      :clearable="$attrs['clearable'] === 'false' ? false : true"
      :dense="$attrs['dense'] === 'false' ? false : true"
      :label="$attrs['label'] ? $attrs['label'] : 'Ders'"
      ref="autocomplete"
      @blur="sortSelection"
      :filter="filterTR"
    >
      <template v-if="'multiple' in $attrs" v-slot:prepend-item>
        <div class="mx-4"><v-btn text small block @click="selectAll()"> Tümünü Seç</v-btn></div>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index < 2">
          <span>{{ item.Name }}</span>
        </v-chip>
        <span v-if="index === 2" class="grey--text text-caption">
          (+{{ value.length - 2 }} seçili)
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'core-lesson',
  props: ['value'],
  data() {
    return { items: [], loading: false }
  },
  mounted: function () {
    this.getDataFromApi()
  },
  methods: {
    sortSelection() {
      if ('multiple' in this.$attrs && this.items && this.value)
        this.items.sort((x, y) => {
          if (this.value.includes(y.Id)) return 1
          else if (this.value.includes(x.Id)) return -1
          else return 0
        })
    },
    refreshItems() {
      this.items = []
      if ('multiple' in this.$attrs) {
        this.$emit('input', [])
      } else {
        this.$emit('input', 0)
      }
      this.getDataFromApi()
    },
    selectAll() {
      this.$emit(
        'input',
        this.items.map((s) => s.Id)
      )
      this.$refs.autocomplete.blur()
    },
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Lesson', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          NameList: true,
          ClassLevelId: this.classLevelId ?? 0,
          ClassBranchIds: this.classBranchIds ?? [],
          ClassLevelIds: this.classLevelIds ?? [],
        }
      })
        .then((data) => {
          this.items = data.data.Results
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
    }
  }
}
</script>
