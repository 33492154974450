var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.datas,
            label: "Form Tipi",
            "item-text": (item) => item.Name,
            "item-value": (item) => item.Id,
            outlined: "",
            clearable: "",
            dense: "",
            "hide-details": "",
            filter: _vm.filterTR,
          },
        },
        "v-autocomplete",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }