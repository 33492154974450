import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import Swal from 'sweetalert2'
// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGINBYKEY = 'loginbykey'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const VERIFYREGISTER = 'verifyregister'
export const UPDATE_PASSWORD = 'updateUser'
export const CHANGE_TOKEN = 'changeToken'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'

export const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  }
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.setHeader()
      ApiService.post('api/Login', {
        email: credentials.email,
        password: credentials.password,
        type: credentials.type
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          resolve(response)
        })
    })
  },
  [LOGINBYKEY](context, credentials) {
    return new Promise((resolve) => {
      ApiService.setHeader()
      ApiService.post('api/LoginStudentByKey', {
        Key: credentials.key
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          resolve(response)
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },

  [CHANGE_TOKEN](context, data) {
    context.commit(SET_AUTH, data)
  },
  [REGISTER](context, credentials) {
    ApiService.setHeader()
    return new Promise((resolve) => {
      ApiService.put('/api/register', credentials)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          resolve(response)
        })
    })
  },
  [VERIFYREGISTER](context, credentials) {
    ApiService.setHeader()
    return new Promise((resolve) => {
      ApiService.put('/api/verifyregister', credentials)
        .then(({ data }) => {
          context.commit(LOGIN, {
            email: credentials.Email,
            password: credentials.Password
          })
          resolve(data)
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          resolve(response)
        })
    })
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader()
      ApiService.get('api/login/verify')
        .then((data) => {
          context.commit(SET_AUTH, data.data)
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          context.commit(PURGE_AUTH)
          // context.commit(SET_ERROR, response.data.errors);
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload
    ApiService.setHeader()
    return ApiService.put('password', password)
      .then(({ data }) => {
        context.commit(SET_PASSWORD, data)
        return data
      })
      .catch(({ response }) => {
        ApiService.showError(response)
        context.commit(PURGE_AUTH)
        // context.commit(SET_ERROR, response.data.errors);
      })
  }
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error

    Swal.fire({
      title: 'Hata',
      text: state.errors[0],
      icon: 'error',
      confirmButtonClass: 'btn btn-secondary',
      heightAuto: false
    })
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = {
      User: user,
      Id: user.User.Id,
      Email: user.User.Email,
      LastName: user.User.LastName,
      FirstName: user.User.FirstName,
      ProxyUser: user.User.ProxyUser
    }
    state.errors = {}
    JwtService.saveToken(user.Token)
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
