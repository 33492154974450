import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
const vuetify = new Vuetify()

Vue.use(Vuetify)

Vue.use({
  vuetify,
  iconsGroup: 'md'
})

import tr from 'vuetify/src/locale/tr.ts'

export default new Vuetify({
  lang: {
    locales: { tr },
    current: 'tr'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#5867dd',
        secondary: '#e8ecfa',
        accent: '#5d78ff',
        error: '#fd397a',
        info: '#5578eb',
        success: '#0abb87',
        warning: '#ffb822'
      }
    }
  },
  breakpoint: {
    mobileBreakpoint: 992
  }
})
