import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
import {VERIFY_AUTH} from '@/core/services/store/auth.module'
import {RESET_LAYOUT_CONFIG} from '@/core/services/store/config.module'
import {applyPolyfills, defineCustomElements} from '@bruit/component/loader'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/bruit-\w*/]
applyPolyfills().then(() => {
    defineCustomElements(window)
})
// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'

window.PerfectScrollbar = PerfectScrollbar

// Vue 3rd party plugins
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/metronic'
import '@/core/plugins/coreprod'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-file-agent/dist/vue-file-agent.css'


import VueFileAgent from 'vue-file-agent'

Vue.use(VueFileAgent)

// API service init
ApiService.init()

Vue.mixin({
    methods: {
        filterTR: function (item, queryText, itemText) {
            return itemText.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1
        }
    },
})
Vue.filter('formattedDate', function (date) {
    if (date) return new Intl.DateTimeFormat('tr-TR').format(new Date(date))
    else return ''
})
Vue.filter('formattedDateTime', function (date) {
    if (date)
        return new Intl.DateTimeFormat('tr-TR', {dateStyle: 'short', timeStyle: 'short'}).format(
            new Date(date)
        )
    else return ''
})

Date.prototype.isValid = function () {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return this.getTime() === this.getTime();
};
Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
}
router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
        if (to.name == 'odev-planlama' && vuetify.framework.breakpoint.mobile)
            next({name: 'odev-planlama-mobil', query: to.query},)
        else
            next()
    })

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG)

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')
