<template>
  <div class="form-group">
    <select
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
        list="items"
        name="tenant"
        id="tenantInput"
        placeholder="Okul"
        autocomplete="off"
        aria-autocomplete="none"
        @change="onChange($event)"
    >
      <template v-for="item in items">
        <option :key="item.Value" :value="item.Value">{{ item.Name }}</option>
      </template>
    </select>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'core-tenant',
  data() {
    return {items: [], loading: true}
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/App/tenant-list', {
        sortBy: ['DisplayOrder'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then(({data}) => {
            this.items = data.Results
            this.loading = false

            const tid = localStorage.getItem('TID')
            if (tid) {
              let element = document.getElementById('tenantInput');
              element.value = tid;
            }

          })
          .catch(() => (this.loading = false))
    },
    onChange(event) {
      localStorage.setItem('TID', event.target.value)
    }
  }
}
</script>
