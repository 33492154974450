<template>
  <div>
    <v-text-field
      v-bind="$attrs"
      v-model.lazy="computedDateTimeValue"
      v-mask="'##.##.#### ##:##'"
      prepend-icon="mdi-calendar"
      :label="label"
      @click:prepend="modal = !modal"
      @blur="changeDateTime"
      v-on:keyup.enter="changeDateTime"
      @click:append="$emit('click-append-event')"
      :rules="[rules.dateTimeCheck]"
    >
    </v-text-field>
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
      <v-date-picker first-day-of-week="1"
        v-model="date"
        v-show="!timesection"
        scrollable
        no-title
        @input="timesection = true"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> İptal </v-btn>
      </v-date-picker>
      <v-time-picker
        v-if="modal && timesection"
        v-model="time"
        format="24hr"
        full-width
        @click:minute="
          modal = false
          timesection = false
        "
      ></v-time-picker>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'core-datetime-picker',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      init: false,
      modal: false,
      timesection: false,
      dateFormatted: '',
      date: '',
      time: '',
      rules: {
        required: (value) => !!value || 'Gerekli.',
        dateTimeCheck: (v) =>
          !v ||
          moment(v, 'DD.MM.YYYY HH:mm', true).isValid() ||
          'GG.AA.YYYY SS:DD formatına uygun değil'
      }
    }
  },
  components: {},
  computed: {
    computedDateTimeValue() {
      if (this.dateFormatted != '' && this.time != '') {
        return `${this.dateFormatted} ${this.time}`
      }
      return ''
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
      if (!this.init && val) {
        this.init = true
      }
      if (moment(this.date + 'T' + this.time + ':00', 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        this.$emit('input', moment(this.date + ' ' + this.time).format('YYYY-MM-DDTHH:mm:ss'))
      }
    },
    time(val) {
      if (!this.init && val) {
        this.init = true
      }
      if (moment(this.date + 'T' + this.time + ':00', 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        this.$emit('input', moment(this.date + ' ' + this.time).format('YYYY-MM-DDTHH:mm:ss'))
      }
    },
    value(val) {
      if (moment(val).format('YYYY-MM-DD') != 'Invalid date') {
        this.date = moment(val).format('YYYY-MM-DD')
        this.time = moment(val).format('HH:mm')
      } else {
        this.date = ''
        this.time = ''
        this.$emit('input', null)
      }
    }
  },
  mounted: function () {
    if (this.value) {
      this.date = moment(this.value).format('YYYY-MM-DD')
      this.time = moment(this.value).format('HH:mm')
    }
    if (!this.init && this.value) {
      this.init = true
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      const [year, month, day] = date.split('-')
      if (day && day.length == 2 && month && month.length == 2 && year && year.length == 4)
        return `${day}.${month}.${year}`
      else return null
    },
    changeDateTime(e) {
      if (moment(e.target.value, 'DD.MM.YYYY HH:mm', true).isValid()) {
        this.date = moment(e.target.value, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD')
        this.time = moment(e.target.value, 'DD.MM.YYYY HH:mm').format('HH:mm')
      } else {
        this.date = ''
        this.time = ''
      }
    }
  }
}
</script>
