<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';
@import '~shepherd.js/dist/css/shepherd.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import {OVERRIDE_LAYOUT_CONFIG} from '@/core/services/store/config.module'
import {Network} from '@capacitor/network'
import Swal from 'sweetalert2'
import {Capacitor} from "@capacitor/core";
import {PushNotifications} from '@capacitor/push-notifications'

export default {
  name: 'CoreProductivity',
  mounted() {
    Network.addListener('networkStatusChange', (status) => {
      if (status.connected == false) {
        Swal.fire({
          title: 'Hata',
          text: 'İnternet bağlantınız yok.',
          position: 'center',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
          heightAuto: false,
          allowOutsideClick: false,
          confirmButtonText: 'Sayfayı yenile'
        }).then(function () {
          window.location.reload()
        })
      }
    })

    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('pushNotificationActionPerformed',
          (notification) => {
            if (notification.notification.data.path != null && notification.notification.data.path != '')
              this.$router.push(notification.notification.data.path)
          }
      );
    }

    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)
  }
}
</script>
