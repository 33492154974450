var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-autocomplete",
        _vm._g(
          _vm._b(
            {
              ref: "autocomplete",
              attrs: {
                items: _vm.items,
                loading: _vm.loading,
                "item-text": (item) => item.Name,
                "item-value": (item) => item.Id,
                "hide-details":
                  _vm.$attrs["hide-details"] === "false" ? false : "auto",
                outlined: _vm.$attrs["outlined"] === "false" ? false : true,
                clearable: _vm.$attrs["clearable"] === "false" ? false : true,
                dense: _vm.$attrs["dense"] === "false" ? false : true,
                label: _vm.$attrs["label"] ? _vm.$attrs["label"] : "Ders",
                filter: _vm.filterTR,
              },
              on: { blur: _vm.sortSelection },
              scopedSlots: _vm._u(
                [
                  "multiple" in _vm.$attrs
                    ? {
                        key: "prepend-item",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "mx-4" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", small: "", block: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectAll()
                                      },
                                    },
                                  },
                                  [_vm._v(" Tümünü Seç")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "selection",
                    fn: function ({ item, index }) {
                      return [
                        index < 2
                          ? _c("v-chip", { attrs: { small: "" } }, [
                              _c("span", [_vm._v(_vm._s(item.Name))]),
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.value.length - 2) +
                                    " seçili) "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            "v-autocomplete",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }