var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-select",
        _vm._g(
          _vm._b(
            {
              attrs: {
                items: _vm.items,
                "item-text": (item) => item.Name,
                "item-value": (item) => item.Id,
                "hide-details":
                  _vm.$attrs["hide-details"] === "false" ? false : "auto",
                outlined: _vm.$attrs["outlined"] === "false" ? false : true,
                clearable: _vm.$attrs["clearable"] === "false" ? false : true,
                dense: _vm.$attrs["dense"] === "false" ? false : true,
                label: _vm.$attrs["label"]
                  ? _vm.$attrs["label"]
                  : "Akademik Yıl",
              },
            },
            "v-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }