<template>
  <div>
    <v-text-field
      v-bind="$attrs"
      v-model.lazy="dateFormatted"
      v-mask="'##.##.####'"
      prepend-icon="mdi-calendar"
      outlined
      :label="label"
      dense
      @click:prepend="modal = !modal"
      @blur="date = parseDate(dateFormatted)"
      v-on:keyup.enter="date = parseDate(dateFormatted)"
      @click:append="$emit('click-append-event')"
      :rules="[rules.dateCheck]"
    >
    </v-text-field>
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
      <v-date-picker first-day-of-week="1" v-model="date" scrollable no-title @input="modal = false">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> İptal </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-menu v-model="menu2" :close-on-content-click="false">
      <v-date-picker first-day-of-week="1" v-model="date" no-title @input="menu2 = false"></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'core-datetime-picker',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      init: false,
      menu2: false,
      modal: false,
      dateFormatted: '',
      date: '',
      rules: {
        required: (value) => !!value || 'Required.',
        dateCheck: (v) =>
          !v || moment(v, 'DD.MM.YYYY', true).isValid() || 'GG.AA.YYYY formatına uygun değil'
      }
    }
  },
  components: {},
  computed: {},
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
      if (!this.init && val) {
        this.init = true
      }
      this.$emit('input', val)
    },
    value(val) {
      if (!this.init || moment(val, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        this.init = true
        if (val) this.date = moment(val).format('YYYY-MM-DD')
      } else if (val) {
        const [year, month, day] = val.split('-')
        if (day && day.length == 2 && month && month.length == 2 && year && year.length == 4)
          this.$emit('input', val)
        else {
          this.$emit('input', null)
        }
      } else {
        this.date = ''
        this.$emit('input', null)
      }
    }
  },
  mounted: function () {
    if (this.value) this.date = moment(this.value).format('YYYY-MM-DD')
    if (!this.init && this.value) {
      this.init = true
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      const [year, month, day] = date.split('-')
      if (day && day.length == 2 && month && month.length == 2 && year && year.length == 4)
        return `${day}.${month}.${year}`
      else return null
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      if (day && day.length == 2 && month && month.length == 2 && year && year.length == 4)
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      else return ''
    }
  }
}
</script>
