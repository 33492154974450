import Vue from 'vue'
import CDatePicker from '@/view/components/CDatePicker.vue'
import CDateTimePicker from '@/view/components/CDateTimePicker.vue'
import CAcademicYear from '@/view/components/CAcademicYear.vue'
import CTenant from '@/view/components/CTenant.vue'
import CFormType from '@/view/components/CFormType.vue'
import CLesson from '@/view/components/CLesson.vue'

Vue.component('c-date-picker', CDatePicker)
Vue.component('c-datetime-picker', CDateTimePicker)
Vue.component('c-academic-year', CAcademicYear)
Vue.component('c-tenant', CTenant)
Vue.component('c-form-type', CFormType)
Vue.component('c-lesson', CLesson)
