<template>
  <v-autocomplete
      :items="datas"
      label="Form Tipi"
      :item-text="(item) => item.Name"
      :item-value="(item) => item.Id"
      v-bind="$attrs"
      outlined
      clearable
      dense
      v-on="$listeners"
      hide-details
      :filter="filterTR"
  >
  </v-autocomplete>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'core-form-type',
  data() {
    return {
      datas: [],
    }
  },
  mounted: function () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/FormType', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
      .then((data) => {
        this.datas = data.data.Results
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    }
  }
}
</script>
