import Vue from 'vue'
import Router from 'vue-router'
import { Capacitor } from '@capacitor/core'

Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/menu',
          component: () => import('@/view/pages/vuetify/Vuetify.vue'),
          children: [
            {
              path: '/dashboard',
              name: 'dashboard',
              component: () => import('@/view/pages/Dashboard.vue')
            },
            {
              name: 'profil-duzenle',
              path: '/profil-duzenle',
              component: () => import('@/view/pages/profile/EditProfile')
            },
            {
              name: 'veli-profil-duzenle',
              path: '/veli-profil-duzenle',
              component: () => import('@/view/pages/profile/Customer/EditProfile')
            },
            {
              name: 'sifre-sifirla',
              path: '/sifre-sifirla',
              component: () => import('@/view/pages/profile/ResetPassword')
            },
            {
              name: 'hakkimizda',
              path: '/hakkimizda',
              component: () => import('@/view/pages/profile/AboutUs')
            },
            {
              name: 'odevler',
              path: '/odevler',
              props: { orderByEndDate: true },
              component: () => import('@/view/pages/homework/Homeworks')
            }, 
            {
              name: 'odev-planlama',
              path: '/odev-planlama',
              component: () => import('@/view/pages/homework/HomeworkPlaning.vue')
            },
            {
              name: 'odev-planlama-mobil',
              path: '/odev-planlama-mobil',
              component: () => import('@/view/pages/homework/HomeworkPlaningMobile.vue')
            },
            {
              name: 'calisma-planim',
              path: '/calisma-planim',
              component: () => import('@/view/pages/homework/HomeworkPlan.vue')
            },
            {
              name: 'odevlerim',
              path: '/odevlerim',
              component: () => import('@/view/pages/homework/MyHomeworks')
            },
            {
              name: 'odev-raporu',
              path: '/odev-raporu',
              component: () => import('@/view/pages/report_widgets/StudentHomeworkReportWidget')
            },
            {
              name: 'odev-unite-konu-raporu',
              path: '/odev-unite-konu-raporu',
              component: () =>
                import('@/view/pages/homework/StudentLessonUnitSubjectHomeworkReport')
            },
            {
              name: 'odev-kaynak-raporu',
              path: '/odev-kaynak-raporu',
              component: () => import('@/view/pages/report_widgets/StudentHomeworkSourceReport')
            },
            {
              name: 'raporlar',
              path: '/raporlar',
              component: () => import('@/view/pages/Reports')
            },
            {
              name: 'sinavlar',
              path: '/sinavlar',
              component: () => import('@/view/pages/exam/Exams')
            },
            {
              name: 'sinav-sonuclari',
              path: '/sinav-sonuclari',
              component: () => import('@/view/pages/exam/ExamResults')
            },
            {
              name: 'sinav',
              path: '/sinav/:id',
              component: () => import('@/view/pages/exam/Exam')
            },
            {
              name: 'oturum',
              path: '/oturum/:id',
              component: () => import('@/view/pages/exam/ExamSession')
            },
            {
              name: 'ders-programi',
              path: '/ders-programi',
              props: { dates: [] },
              component: () => import('@/view/pages/LessonTime')
            },
            {
              name: 'ayarlar',
              path: '/ayarlar',
              component: () => import('@/view/pages/Settings')
            },
            {
              name: 'devamsizliklar',
              path: '/devamsizliklar',
              component: () => import('@/view/pages/attendance/AttendanceDetails')
            },
            {
              name: 'odev-cevaplari',
              path: '/odev-cevaplari/:homeworkId/:sourceTestId',
              component: () => import('@/view/pages/homework/HomeworkAnswers')
            },
            {
              name: 'odev-karnesi',
              path: '/odev-karnesi',
              component: () => import('@/view/pages/homework/HomeworkReport')
            },
            {
              name: 'sinav-raporu',
              path: '/sinav-raporu',
              component: () => import('@/view/pages/exam/CombinedResultReport')
            },
            {
              name: 'saglik-raporlari',
              path: '/saglik-raporlari',
              component: () => import('@/view/pages/attendance/HealthReports')
            },
            {
              name: 'randevular',
              path: '/randevular',
              component: () => import('@/view/pages/appointment/Appointments')
            },
            {
              name: 'gorusmeler',
              path: '/gorusmeler',
              component: () => import('@/view/pages/Meeting/Meetings')
            },
            {
              name: 'gorusme',
              path: '/gorusme/:id',
              component: () => import('@/view/pages/Meeting/MeetingDetails')
            },
            {
              name: 'bildirimler',
              path: '/bildirimler',
              component: () => import('@/view/pages/notification/Notifications')
            },
            {
              name: 'formlar',
              path: '/formlar',
              component: () => import('@/view/pages/form/Forms')
            },
            {
              name: 'form-karnesi',
              path: '/form-karnesi/:id',
              component: () => import('@/view/pages/form/ElementaryFormUserReport')
            },
            {
              name: 'ingilizce-form-karnesi', //deprecated
              path: '/ingilizce-form-karnesi/:id',
              component: () => import('@/view/pages/form/EnglishFormUserReport.vue')
            },
            {
              name: 'genel-form-karnesi',
              path: '/genel-form-karnesi/:id',
              component: () => import('@/view/pages/form/FormUserReport.vue')
            },
            {
              name: 'birlesik-form-karnesi',
              path: '/birlesik-form-karnesi/:id',
              component: () => import('@/view/pages/form/CombinedFormUserReport.vue')
            },
            {
              name: 'diger',
              path: '/diger',
              component: () => import('@/view/pages/profile/StudentExtras')
            }, 
            {
              name: 'pano',
              path: '/pano',
              component: () => import('@/view/pages/social_school/Panel.vue')
            },
            {
              name: 'icerik',
              path: '/icerik/:id',
              component: () => import('@/view/pages/social_school/Content.vue')
            },
            {
              name: 'qr-kod',
              path: '/qr-kod',
              component: () => import('@/view/components/CreateQrCode.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/',
      component: function () {
        if (Capacitor.isNativePlatform()) {
          return import('@/view/login/CommonLogin')
        } else {
          return import('@/view/login/CoreLogin')
        }
      },
      children: [
        {
          name: 'login',
          path: '/login',
          component: function () {
            if (Capacitor.isNativePlatform()) {
              return import('@/view/login/CommonLogin')
            } else {
              return import('@/view/login/CoreLogin')
            }
          }
        },
        {
          name: 'r',
          path: '/r/:url',
          component: () => import('@/view/login/CoreLogin')
        }
      ]
    }
  ]
})
