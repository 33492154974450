<template>
  <div>
    <v-select
        :items="items"
        :item-text="(item) => item.Name"
        :item-value="(item) => item.Id"
        v-bind="$attrs"
        v-on="$listeners"
        :hide-details="$attrs['hide-details'] === 'false' ? false : 'auto'"
        :outlined="$attrs['outlined'] === 'false' ? false : true"
        :clearable="$attrs['clearable'] === 'false' ? false : true"
        :dense="$attrs['dense'] === 'false' ? false : true"
        :label="$attrs['label'] ? $attrs['label'] : 'Akademik Yıl'"
    >
    </v-select>
  </div>
</template>

<script>
//import CAutoComplete from '@/view/components/CAutoComplete.vue'
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'core-academic-year',
  props: ['editable'],
  // event: 'change',
  data() {
    return {items: []}
  },
  components: {
    /*CAutoComplete*/
  },
  mounted: function () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/AcademicYear', {
        sortBy: ['StartYear'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.items = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
