var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "select",
      {
        staticClass:
          "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
        attrs: {
          list: "items",
          name: "tenant",
          id: "tenantInput",
          placeholder: "Okul",
          autocomplete: "off",
          "aria-autocomplete": "none",
        },
        on: {
          change: function ($event) {
            return _vm.onChange($event)
          },
        },
      },
      [
        _vm._l(_vm.items, function (item) {
          return [
            _c("option", { key: item.Value, domProps: { value: item.Value } }, [
              _vm._v(_vm._s(item.Name)),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }